import React from "react"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import Layout from "../../components/layout"
import Head from "../../components/head"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(15, 0, 12),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(9, 0, 4),
    },
  },
}))

const NptaryPublic = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Head title="Family Law" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Notary Public
            </Typography>

            <Typography component="p" paragraph>
              As a Notary Public in New South Wales I am required firstly to be
              a registered and practising solicitor. Secondly, I am required to
              have completed a course and passed an exam about my duties and
              requirements as a Notary Public.
            </Typography>

            <Typography component="p" paragraph>
              As a Notary Public, my signature and seal are registered in
              foreign consular offices in Australia so that they can
              independently verify my true signature and seal. My signature and
              seal are also registered with the Department of Foreign Affairs
              and Trade which also provides the “Apostille” for notarised
              documents where required.
            </Typography>

            <Typography component="p" paragraph>
              As a Notary Public much of my work involves:
            </Typography>

            <Typography component="ul" paragraph>
              <Typography component="li">
                Certifying that a document is a true copy of an original
                document which is required to be used overseas
              </Typography>
              <Typography component="li">
                Witnessing the signature of person/s on documents required to be
                used overseas
              </Typography>
              <Typography component="li">
                Authenticating original documents for use overseas.
              </Typography>
            </Typography>

            <Typography component="p" paragraph>
              Where a document is provided in a foreign language, I will
              generally ask for it to be translated into English. You would then
              sign both the English language translation and foreign language
              version of the same document. The reason that I ask for a foreign
              language document to be translated is to ensure that it is a valid
              document and that I can confirm with you that you are signing the
              document of your own free will and you are fully aware of its
              contents.
            </Typography>

            <Typography component="p" paragraph>
              You are required to provide two forms of identity – preferably
              your passport and driver licence – when you bring in your
              documents to be notarised.
            </Typography>

            <Typography component="p" paragraph>
              I offer a fixed price service of $150 + GST $15 = $165 for the
              first document ant $50 + GST $5 = $55 for each subsequent document
              required to be notarised at the same time.
            </Typography>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

export default NptaryPublic
